import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import { ThemeProvider } from 'styled-components'
import GlobalStyles from './styles/global'
import { baseTheme } from './styles/theme'
import {HomePage} from "./pages/HomePage";
import {RoomPage} from "./pages/RoomPage";
import {PlaylistPage} from "./pages/PlaylistPage";
import {PlaylistSettingsPage} from "./pages/PlaylistPage/PlaylistSettingsPage";
import {ControlsPage} from "./pages/RoomPage/ControlsPage";
import {StatsPage} from "./pages/RoomPage/StatsPage";
import {SessionPage} from "./pages/RoomPage/SessionPage";
import {AuthMiddleware} from "./middlewares/authMiddleware";
import {PlayerPage} from "./pages/PlayerPage";
import {DelaysPage} from "./pages/DelaysPage";
import {RoomSettingsPage} from "./pages/RoomPage/RoomSettingsPage";
import {AccountPage} from "./pages/AccountPage";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage />
    },
    {
        path: '/room/:room_hash',
        element: <RoomPage />
    },
    {
        path: '/room/:room_hash/playlist/:playlist_id',
        element: <PlaylistPage />
    },
    {
        path: '/room/:room_hash/playlist/:playlist_id/settings',
        element: <PlaylistSettingsPage />
    },
    {
        path: '/room/:room_hash/controls',
        element: <ControlsPage />
    },
    {
        path: '/room/:room_hash/stats',
        element: <StatsPage />
    },
    {
        path: '/room/:room_hash/session/:session_id',
        element: <SessionPage />
    },
    {
        path: '/view2/:room_hash/view',
        element: <PlayerPage />
    },
    {
        path: "/view2/:room_hash/delays",
        element:  <DelaysPage />
    },
    {
        path: '/room/:room_hash/settings',
        element: <RoomSettingsPage />
    },
    {
        path: '/account',
        element: <AccountPage />
    },
]);


root.render(
  <React.StrictMode>
      <ThemeProvider theme={baseTheme}>
          <AuthMiddleware>
            <RouterProvider router={router} />
          </AuthMiddleware>
          <GlobalStyles />
      </ThemeProvider>
  </React.StrictMode>
);
