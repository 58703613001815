import React, {useEffect, useState} from "react";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {Track} from "../../../components/Track";
import {BlockSide} from "../../../components/Block/BlockSide";
import {NowPlayingBlock} from "./components/NowPlayingBlock";
import styled from 'styled-components';
import {Button} from "../../../components/Button";
import {FloatingContainer} from "../../../components/FloatingContainer";
import {AddTrackModal} from "../../../modals/AddTrackModal";
import {Cooldowns} from "./components/Cooldowns";
import {useNavigate} from "react-router-dom";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";
import {Bar} from "../../../components/Bar";

const SSkipBlock = styled.div`
    position: fixed;
    right: 20px;
    bottom: 20px;
`;



const api = new API();

export function PlayingPage({room, isMobile=isMobileDevice()}) {
    const [status, setStatus] = useState(null);
    const [queue, setQueue] = useState(null);
    const [addTrackOpen, setAddTrackOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [canUpvote, setCanUpvote] = useState(null);
    const [canSkip, setCanSkip] = useState(null);
    const [noCd, setNoCd] = useState(null);

    /* loadings */
    const [isSkipLoading, setSkipLoading] = useState(false);

    const navigate = useNavigate();

    function update() {
        api.getRoomStatus(room.hash).then((resp) => {
            if (resp.current_track) setStatus(resp);
            else window.location.reload();
        });
        api.getQueue(room.hash, null).then((resp) => {
            setQueue(resp.tracks);
            setNoCd(resp.no_cd_ends_at);
        })
    }

    function updateUser() {
        api.getUser().then((resp) => {
            setUser(resp);
            setCanSkip(resp.can_skip_at);
            setCanUpvote(resp.can_upvote_at);
        })
    }

    useEffect(() => {
        update();
        updateUser();
        const interval = setInterval(() => {
            update();
        }, 2000);
        const interval1 = setInterval(() => {
            updateUser();
        }, 4000);
        return () => {
            clearInterval(interval);
            clearInterval(interval1)
        }
    }, []);

    const onUpvote = (tid) => {
        return new Promise((resolve, reject) => {
            api.upvote(room.hash, status.current_playlist_id, tid).finally(() => {
                update();
                updateUser();
                resolve();
            });
        });

    };

    const goToPlayer = () => {
        window.location.href = `/view2/${room.hash}/view`;
    };

    const goToControls = () => {
      navigate(`/room/${room.hash}/controls`);
    };

    const skipTrack = () => {
        setSkipLoading(true);
        api.skip(room.hash).finally(() => {
            update();
            updateUser();
            setSkipLoading(false);
        });
    };


    return <div>
        <div className="inlineRoomName">
            <h1>Очередь</h1>
            {isMobile &&
                <Cooldowns isMobile={isMobile} upvote_at={canUpvote} skip_at={canSkip} no_cd_until={noCd} />
            }
        </div>
        <Block style={{marginBottom: '10px'}} height={isMobile ? "100dvh - 284px" : "100dvh - 350px"} smallPadding={isMobile}>
            <BlockSide col={true}>
                {queue && queue.map((tr) => {
                    return <Track key={tr.id} artist={tr.artist} title={tr.title} tid={tr.id} cover={tr.cover} identifier={tr.identifier} votes={room?.enable_upvote ? tr.votes : null}
                        onUpvote={onUpvote}
                    />
                })}
            </BlockSide>
        </Block>
        {status && <NowPlayingBlock artist={status.current_track.artist} title={status.current_track.title} cover={status.current_track.cover} skip_voted={status.skip_voted} skip_target={status.skip_target} />}
        {(!isMobile && room?.enable_skip) && <SSkipBlock>
            <Button size="s" isPrimary={false} onClick={skipTrack} isLoading={isSkipLoading}>Пропустить</Button>
        </SSkipBlock>}

        {!isMobile && <FloatingContainer>
            {room?.enable_add && <Button size="m" onClick={() => setAddTrackOpen(true)}>Добавить трек</Button>}
            <Button size="m" isPrimary={false} onClick={goToPlayer}>Плеер</Button>
            {room.is_host && <Button size="m" isPrimary={false} onClick={goToControls}>Управление</Button>}
            <Block width={180}>
                <Cooldowns upvote_at={canUpvote} skip_at={canSkip} no_cd_until={noCd} />
            </Block>
        </FloatingContainer>}

        {isMobile && (
          <Bar buttons={[
              room?.enable_add ? {text: "Добавить трек", icon: "/icons/plus_gray.svg", onClick: () => setAddTrackOpen(true)} : null,
              {text: "Плеер", icon: "/icons/player.svg", onClick: goToPlayer},
              room?.enable_skip ? {text: "Некст трек", icon: "/icons/skip.png", onClick: skipTrack, isLoading: isSkipLoading} : null,
              room.is_host ? {text: "Управление", icon: "/icons/settings.svg", onClick: goToControls} : null
          ]} />
        )}
        <AddTrackModal room={room} setOpen={setAddTrackOpen} isOpen={addTrackOpen} />
    </div>
}
