import React, {useEffect, useRef, useState} from "react";
import {Header} from "../../components/Header";
import {Container} from "../../components/Container";
import {useAuth} from "../../hooks/auth";
import {API} from "../../api";
import {BlockList} from "../../components/Block/BlockList";
import {Block} from "../../components/Block";
import {useNavigate} from "react-router";
import {RoomStatus} from "../../styles/HomePage/roomStatus";
import {BlockSide} from "../../components/Block/BlockSide";
import {Button} from "../../components/Button";
import {CreateRoomModal} from "../../modals/CreateRoomModal";
import {FloatingContainer} from "../../components/FloatingContainer";
import {AuthVkModal} from "../../modals/AuthVkModal";
import {AuthYmModal} from "../../modals/AuthYmModal";
import {isMobileDevice} from "../../hooks/mobile/isMobile";
import {Track} from "../../components/Track";

const api = new API();

export function AccountPage({isMobile = isMobileDevice()}) {
    const resetAuth = () => {
        api.vk.resetCredentials();
        window.location.href= "/";
    };


    return (<div>
            <Header />
            <Container>
                <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Аккаунт</h1>
                <Block smallPadding={isMobile}>
                    <BlockSide style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                        <Button size="m" responsive={isMobile} isRich={false} isPrimary={false}>Сбросить токен Вк Музыки</Button>
                        <Button size="m" responsive={isMobile} isRich={false} isPrimary={false}>Сбросить токен Яндекс Музыки</Button>
                        <Button size="m" responsive={isMobile} isRich={true} isPrimary={false} onClick={resetAuth}>Выйти из аккаунта</Button>
                    </BlockSide>
                </Block>
            </Container>
        </div>
    )
}
