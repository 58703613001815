import React, {useEffect, useState} from "react";
import {Header} from "../../../components/Header";
import {Container} from "../../../components/Container";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {BackButton} from "../../../components/BackButton";
import {BlockSide} from "../../../components/Block/BlockSide";
import {SPlaylistStats} from "../../PlaylistPage/PlaylistSettingsPage/PlaylistStats/style";
import {DoubleGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/doubleGraph";
import {ActivityGraph} from "../../PlaylistPage/PlaylistSettingsPage/Graph/activityGraph";
import {Track} from "../../../components/Track";
import {convertUTCDateToLocalDate} from "../../../utils/utils";

const api = new API();

export function SessionPage({isMobile=isMobileDevice()}) {
    const navigate = useNavigate();
    const [session, setSession] = useState({});
    const [sessionPlaylists, setSessionPlaylists] = useState(null);
    const [playlistBlocks, setPlaylistBlocks] = useState(null);

    const {room_hash, session_id} = useParams();

    const update = () => {
        api.getSession(room_hash, session_id).then(resp => {
            setSession(resp);
        });
        api.getSessionTracks(room_hash, session_id).then(resp => {
            setSessionPlaylists(resp)
        })
    };

    useEffect(() => {
        update()
    }, []);

    const saveTrack = (playlist_id, track_id) => {
        return new Promise((resolve, reject) => {
            api.saveTrack(room_hash, playlist_id, track_id).then(resolve).catch(reject);
        })
    };

    useEffect(() => {
        if (sessionPlaylists) {
            const res = [];
            for (const playlist of sessionPlaylists) {
                res.push(<Block smallPadding={isMobile}>
                    <BlockSide col={true}>
                        <h3 className="title">Плейлист {playlist.playlist_name}</h3>
                        {playlist.tracks.map((tr) => {

                            const d = new Date(tr.timestamp * 1000);
                            const d1 = convertUTCDateToLocalDate(d).toLocaleTimeString().split(":");
                            return <Track key={tr.identifier} artist={tr.artist} title={tr.title} tid={tr.id}
                                          cover={tr.cover} identifier={tr.identifier} bpm={tr.bpm} energy={tr.energy}
                                          onSave={tr.in_playlist ? undefined : saveTrack} playlist_id={playlist.playlist_id}
                                          label={d1[0] + ":" + d1[1]} />
                        })}
                    </BlockSide>
                </Block>)
            }
            setPlaylistBlocks(res);
        }
    }, [sessionPlaylists]);

    return <div>
        <Header  />
        <Container paddingTop="0px">

            <BackButton backPath={`/room/${room_hash}/stats`} />
            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Статистика сессии</h1>

            <Block smallPadding={isMobile}>
                <BlockSide col={true}>
                    <SPlaylistStats isMobile={isMobile}>

                        <p>Количество добавленных треков</p>
                        <div>
                            <div>{session?.tracks_added}</div>
                        </div>

                        <p>Количество голосов за пропуск</p>
                        <div>
                            <div>{session?.skip_votes}</div>
                        </div>

                        <p>Количество апвоутов</p>
                        <div>
                            <div>{session?.upvotes}</div>
                        </div>

                        <p>Количество прослушанных треков</p>
                        <div>
                            <div>{session?.tracks_listened}</div>
                        </div>

                    </SPlaylistStats>
                </BlockSide>
            </Block>

            <Block smallPadding={isMobile}>
                <DoubleGraph dataS1={session.bpm_graph} dataS2={session.energy_graph} showLabel={false} title="Показатели энергичности и BPM всех прослушанных треков в рамках сессии" />
            </Block>

            <Block smallPadding={isMobile}>
                <ActivityGraph data={session.activity_graphs} title="Показатели активности пользователей в рамках сессии" />
            </Block>

            <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Список добавленных пользователями треков</h1>
            {playlistBlocks}

        </Container>
    </div>
}
